'use client'

import Link from 'next/link'
import { ArticleSchema } from '@/contentful/shared'
import { ArrowRight } from 'lucide-react'

import { contentConfig } from '@/config/content'
import { sortBySubcategory } from '@/lib/utils'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPagination,
} from '@/components/ui/carousel'
import {
  ArticleCard,
  ArticleCardCategory,
  ArticleCardContent,
  ArticleCardHeader,
  ArticleCardHeaderText,
  ArticleCardImage,
  ArticleCardLink,
  ArticleCardTags,
  ArticleCardTitle,
} from '@/components/article-card'

type ArticleProps = {
  articles: ArticleSchema[]
  title?: string
  description?: string
  category?: string
}

export function ArticleCarousel({
  articles,
  title,
  description,
  category,
}: ArticleProps) {
  return (
    <div className="w-full overflow-hidden bg-accent">
      <div className="px-5 py-8 sm:px-8 sm:py-10 lg:p-11">
        <div className="flex max-w-7xl flex-col gap-7 lg:gap-10">
          <div className="flex flex-col justify-between gap-6 sm:flex-row">
            <div className="flex min-w-0 flex-col gap-3">
              <h1 className="heading-xl-regular">
                {title || 'You might also like'}
              </h1>
              <div className="support-styles-intro-text-regular">
                {description || 'Explore more articles related to this topic.'}
              </div>
            </div>
            {category && (
              <Link href={contentConfig.categoryPath(category)} tabIndex={-1}>
                <Button data-testid="view-all-item">
                  View All
                  <ArrowRight className="size-4 stroke-[3]" />
                </Button>
              </Link>
            )}
          </div>
          <Carousel
            opts={{ align: 'start' }}
            aria-label={`Carousel with ${articles.length} items`}
          >
            <CarouselContent>
              {articles.map((article) => {
                const { slug, asset, category, subCategory, headline } =
                  article.fields
                return (
                  <CarouselItem key={slug}>
                    <ArticleCard>
                      <ArticleCardImage
                        src={`https://${asset?.fields.file?.url}`}
                        alt={asset?.fields.description || ''}
                      />
                      <ArticleCardContent>
                        <ArticleCardHeader>
                          <ArticleCardHeaderText>
                            <ArticleCardCategory>
                              {category}
                            </ArticleCardCategory>
                            <ArticleCardTitle>{headline}</ArticleCardTitle>
                          </ArticleCardHeaderText>
                          <ArticleCardTags>
                            {sortBySubcategory(subCategory)?.map((subCat) => {
                              const [category, subcategory] = subCat.split(':')
                              return (
                                <Link
                                  href={contentConfig.subcategoryPath(
                                    category,
                                    subcategory
                                  )}
                                  key={`${category}-${subCat}`}
                                >
                                  <Badge>{subcategory}</Badge>
                                </Link>
                              )
                            })}
                          </ArticleCardTags>
                        </ArticleCardHeader>
                        <ArticleCardLink href={contentConfig.articlePath(slug)}>
                          Read now
                        </ArticleCardLink>
                      </ArticleCardContent>
                    </ArticleCard>
                  </CarouselItem>
                )
              })}
            </CarouselContent>
            <CarouselPagination />
          </Carousel>
        </div>
      </div>
    </div>
  )
}
