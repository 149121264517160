'use client'

import { contentConfig } from '@/config/content'
import { taxonomyManager } from '@/config/marketing'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPagination,
} from '@/components/ui/carousel'
import { CategoryCardPillLink } from '@/components/category-card'

type Subcategory = {
  label: string
  value: string
}

type Category = {
  label: string
  value: string
  description: string
  asset: string
  subcategories: Subcategory[]
}

type SubcategoryCarouselProps = {
  fields: {
    internalName: string
    title: string
    description: string
  }
}

interface UniqueSubcategory {
  category: string
  label: string
  value: string
}

function getUniqueSubcategories(categories: Category[]): UniqueSubcategory[] {
  const uniqueSubcategories = new Map<string, UniqueSubcategory>()
  categories
    .flatMap((category) =>
      category.subcategories.map((subcategory) => ({
        category: category.value,
        label: subcategory.label,
        value: subcategory.value,
      }))
    )
    .forEach((subcategory) =>
      uniqueSubcategories.set(subcategory.value, subcategory)
    )

  return Array.from(uniqueSubcategories.values())
}

export function AllFeaturedTags({ fields }: SubcategoryCarouselProps) {
  const Categories = taxonomyManager.categories
  const uniqueSubcategories = getUniqueSubcategories(Categories)
  return (
    <div className="w-full overflow-hidden">
      <div className="flex flex-col gap-7 bg-background p-5 sm:gap-6 sm:px-9 sm:py-7 md:px-8 lg:px-11 lg:pb-11 lg:pt-8">
        <p className="heading-xs-bold text-foreground">{fields?.title}</p>
        <Carousel
          opts={{
            align: 'start',
            dragFree: true,
          }}
        >
          <CarouselContent className="flex flex-row gap-2 md:gap-4 pb-3 md:pb-4 lg:pb-0 ">
            {uniqueSubcategories.map((subcategory) => (
              <CarouselItem
                key={`${subcategory.category}-${subcategory.value}`}
                className="!basis-auto"
              >
                <CategoryCardPillLink
                  href={contentConfig.subcategoryPath(
                    `${subcategory.category}`,
                    subcategory.value
                  )}
                >
                  {subcategory.label}
                </CategoryCardPillLink>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPagination />
        </Carousel>
      </div>
    </div>
  )
}
